import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'

type LinkProps = {
  query?: string
  children?: ReactNode
  skipParams?: boolean
}

export const Link: FC<NextLinkProps & LinkProps> = ({
  children,
  href: baseHref,
  query,
  skipParams,
  ...props
}) => {
  const [queryParams, setQueryParams] = useState<string>()

  useEffect(() => {
    if (typeof window === 'undefined') return

    const urlQueryParams = skipParams ? false : window.location.search.replace('?', '')

    const params =
      urlQueryParams || query
        ? [query, urlQueryParams].filter((value) => value).join('&')
        : undefined

    setQueryParams(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const href = queryParams ? `${baseHref}?${queryParams}` : baseHref

  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  )
}
