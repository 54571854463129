import { styled } from 'stitches.config'

export const VisuallyHidden = styled('span', {
  boxSizing: 'border-box',
  border: 'none',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
  // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
  $whiteSpace: 'nowrap',
  wordWrap: 'normal',
})
