import { styled } from 'stitches.config'

export const BadgeCount = styled('span', {
  color: '$accent-white',
})

export const BadgeContainer = styled('sup', {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  $$badgeSize: '$sizes$2',
  height: '$$badgeSize',
  minWidth: '$$badgeSize',
  fontSize: '$caption',
  lineHeight: '$caption',
  px: '4px',
  borderRadius: '$round',

  variants: {
    placement: {
      leftTop: {
        left: 0,
        transform: 'translate(-50%, -50%)',
      },
      rightTop: {
        right: 0,
        transform: 'translate(50%, -50%)',
      },
    },

    isDotSize: {
      true: {
        $$badgeSize: '$sizes$1',
        size: '$$badgeSize',
      },
    },
  },

  defaultVariants: {
    placement: 'rightTop',
  },
})

export const BadgeRoot = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
})
