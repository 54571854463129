import React from 'react'
import { ButtonRoot } from './Button.styles'
import { Icon } from 'components/Icon'
import { IconName } from 'components/Icon/iconNames'
import type * as Polymorphic from '@radix-ui/react-polymorphic'

type ButtonOwnProps = Polymorphic.OwnProps<typeof ButtonRoot>
export type ButtonCustomProps = {
  icon?: IconName
}

export type ButtonProps = ButtonOwnProps & ButtonCustomProps

type PolymorphicButton = Polymorphic.ForwardRefComponent<'button', ButtonProps>

export const Button = React.forwardRef(({ children, icon, ...rest }, forwardedRef) => {
  const onlyIcon = !children && !!icon
  return (
    <ButtonRoot ref={forwardedRef} onlyIcon={onlyIcon} {...rest}>
      {icon && (
        <Icon name={icon} css={{ paddingRight: onlyIcon ? '0' : '$2' }} data-testid={icon} />
      )}
      {children}
    </ButtonRoot>
  )
}) as PolymorphicButton

Button.displayName = 'Button'
