import React, { ComponentProps, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'hooks/useTranslation'
import { Icon } from 'components/Icon'
import { IconName } from 'components/Icon/iconNames'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { VisuallyHidden } from 'components/VisuallyHidden'
import { ColorTokens, FlattenVariant } from 'stitches.config'
import { CloseButton, RoundContainer, ToastRoot } from './Toast.styles'
import { useToast } from 'contexts/ToastContext'

type ToastOwnProps = ComponentProps<typeof ToastRoot>
export type ToastTones = FlattenVariant<ToastOwnProps['tone']>

export type ToastProps = Omit<ToastOwnProps, 'tone'> & {
  id: string
  title?: string
  message: string
  tone?: ToastTones
  duration?: number
  onClose?: () => void
}

const toastVariantToIconMap: Record<ToastTones, IconName> = {
  success: 'check-fill',
  error: 'close-line',
  info: 'information-line',
}

export const Toast = ({
  id,
  duration,
  title,
  message,
  tone = 'info',
  onClose,
  ...rest
}: ToastProps) => {
  const iconColors: Record<ToastTones, ColorTokens> = {
    success: 'success-500',
    error: 'primary-500',
    info: 'tertiary-600',
  }
  const { removeToast } = useToast()

  const timeoutRef = useRef<any>()

  const iconName = useMemo(() => toastVariantToIconMap[tone], [tone])
  const { t } = useTranslation()

  const startRemoveTimer = useCallback(() => {
    timeoutRef.current = setTimeout(() => removeToast(id), duration)
  }, [id, duration, timeoutRef, removeToast])

  const stopRemoveTimer = () => clearTimeout(timeoutRef.current)

  useEffect(() => {
    startRemoveTimer()

    return stopRemoveTimer
  }, [startRemoveTimer])

  return (
    <ToastRoot
      tone={tone}
      {...rest}
      role="status"
      onMouseEnter={() => {
        stopRemoveTimer()
      }}
      onMouseLeave={() => {
        startRemoveTimer()
      }}
    >
      <Stack gap="2" alignItems="flex-start">
        <RoundContainer>
          <Icon name={iconName} color={iconColors[tone]} />
        </RoundContainer>

        <Stack direction="column" css={{ flex: 1 }}>
          <Text weight="medium" color="tertiary-900">
            {title}
          </Text>
          <Text size="small" color="tertiary-700">
            {message}
          </Text>
        </Stack>

        <CloseButton onClick={onClose} css={{ marginLeft: 'auto' }}>
          <Icon name="close-line" color="tertiary-600" />
          <VisuallyHidden>{t('common:close')}</VisuallyHidden>
        </CloseButton>
      </Stack>
    </ToastRoot>
  )
}

Toast.displayName = 'Toast'
