import React, { ComponentProps } from 'react'
import { ColorTokens } from 'stitches.config'
import { BadgeContainer, BadgeCount, BadgeRoot } from './Badge.styles'

type BadgeOwnProps = ComponentProps<typeof BadgeContainer>

export type BadgeProps = BadgeOwnProps & {
  label: string
  count?: number
  overflowCount?: number
  hidden?: boolean
  hiddenCount?: boolean
  color?: ColorTokens
  offset?: number[]
}

export const Badge = ({
  label,
  count = 0,
  overflowCount = 99,
  hidden,
  hiddenCount = false,
  color,
  placement,
  offset = [0, 0],
  children,
  ...rest
}: BadgeProps) => {
  const isOverflowed = count > overflowCount
  const content = isOverflowed ? `${overflowCount}+` : count
  const badgeContainerCss = {
    backgroundColor: `$${color}`,
    top: `${offset[1]}px`,
    left: placement === 'leftTop' ? `${offset[0]}px` : 'auto',
    right: placement === 'rightTop' ? `${-offset[0]}px` : 'auto',
  }

  return (
    <BadgeRoot>
      {!hidden && (
        <BadgeContainer
          placement={placement}
          isDotSize={hiddenCount}
          css={badgeContainerCss}
          role="alert"
          aria-label={label}
          {...rest}
        >
          {!hiddenCount && <BadgeCount>{content}</BadgeCount>}
        </BadgeContainer>
      )}
      {children}
    </BadgeRoot>
  )
}

Badge.displayName = 'Badge'
