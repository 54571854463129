import { keyframes, styled } from 'stitches.config'
import { FooterRoot } from 'components/Footer/Footer.styles'

export const LoaderRoot = styled('div', {
  backgroundColor: '$secondary-100',

  '&': {
    [`${FooterRoot}`]: {
      borderTop: '12px solid transparent',
      borderBottom: 0,
      borderImage: 'url(/assets/pattern-footer-border-mobile.svg) 30',

      '@bp2': {
        borderImage: 'url(/assets/pattern-footer-border-desktop.svg) 30',
      },
    },
  },
})

export const Wrapper = styled('div', {
  $$footerHeight: '373px',
  $$mobileFooterHeight: '606px',

  minHeight: 'calc(100vh - $$mobileFooterHeight)',
  maxWidth: '$max',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '256px',
  paddingBottom: '176px',

  '@bp1': {
    minHeight: 'calc(100vh - $$footerHeight)',
  },

  '@bp2': {
    minHeight: 'calc(100vh  - $$footerHeight)',
  },
})

export const LoaderWrapper = styled('div', {
  width: '96px',
  height: '165px',

  '@bp2': {
    width: '113px',
    height: '195px',
  },
})

const scale = keyframes({
  '0%, 100%': {
    transform: 'scale(.75)',
  },
  '50%': {
    transform: 'scale(1)',
  },
})

export const SmallLoaderWrapper = styled('div', {
  display: 'flex',
  gap: '3px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
  height: '30px',
  ':nth-child(1)': {
    animation: `${scale} 2s ease-in-out infinite`,
    animationDelay: '0s',
  },
  ':nth-child(2)': {
    animation: `${scale} 2s ease-in-out infinite`,
    animationDelay: '0.5s',
  },
  ':nth-child(3)': {
    animation: `${scale} 2s ease-in-out infinite`,
    animationDelay: '1s',
  },
})
