import React, { ComponentProps, ReactNode } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'
import { PopoverArrow, PopoverContent } from './Popover.styles'

type PopoverOwnProps = ComponentProps<typeof PopoverContent>
export type PopoverProps = PopoverOwnProps & {
  trigger: ReactNode
  instant?: boolean
  onOpenChange?: (open: boolean) => void
  open?: boolean
}

export const Popover = ({
  children,
  trigger,
  side = 'top',
  align = 'center',
  onOpenChange,
  open,
  sideOffset = 4,
  ...props
}: PopoverProps) => {
  return (
    <RadixPopover.Root onOpenChange={onOpenChange} open={open}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <PopoverContent side={side} align={align} sideOffset={sideOffset} {...props}>
        {children}
        <PopoverArrow offset={4} />
      </PopoverContent>
    </RadixPopover.Root>
  )
}

Popover.displayName = 'Popover'
