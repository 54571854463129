import { styled } from 'stitches.config'

export const buttonReset = {
  border: 'none',
  margin: '0',
  padding: '0',
  width: 'auto',
  overflow: 'visible',

  background: 'transparent',
  color: 'inherit',
  font: 'inherit',
  lineHeight: 'normal',
  '-webkit-font-smoothing': 'inherit',
  '-moz-osx-font-smoothing': 'inherit',

  '-webkit-appearance': 'none',
}

export const ButtonRoot = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  py: '$2',
  px: '$4',
  borderRadius: '32px',
  color: '$accent-white',
  fontWeight: '$medium',
  fontSize: '$body',
  height: '$$size',

  '&:disabled': {
    cursor: 'not-allowed',
  },

  variants: {
    size: {
      tiny: {
        $$size: '$sizes$4',
        size: '$$size',
        py: '$1',
      },
      small: {
        $$size: '$sizes$5',
        py: '$1',
      },
      regular: {
        $$size: '$sizes$7',
        fontSize: 'body',
      },
    },
    onlyIcon: {
      true: {
        py: 0,
        px: 0,
        minWidth: 0,
        borderRadius: '$2',
        border: '1px solid',

        '&': {
          size: '$$size',
        },
      },
    },
    variant: {
      primary: {
        backgroundColor: '$primary-500',
        '&:hover': {
          backgroundColor: '$primary-400',
        },
        '&:active': {
          backgroundColor: '$primary-600',
        },
        '&:disabled': {
          backgroundColor: '$primary-200',
        },
      },
      secondary: {
        backgroundColor: '$tertiary-600',
        '&:hover': {
          backgroundColor: '$tertiary-500',
        },
        '&:active': {
          backgroundColor: '$tertiary-800',
        },
        '&:disabled': {
          backgroundColor: '$tertiary-200',
        },
      },
      tertiary: {
        color: '$primary-500',
        backgroundColor: '$accent-white',
        border: '2px solid $primary-500',
        '&:hover': {
          color: '$primary-300',
          border: '2px solid $primary-300',
        },
        '&:active': {
          color: '$primary-700',
          border: '2px solid $primary-700',
        },
        '&:disabled': {
          color: '$primary-100',
          border: '2px solid $primary-100',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'primary',
    size: 'regular',
  },
})
