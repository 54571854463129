import { keyframes, styled } from 'stitches.config'
import { buttonReset } from 'components/Button/Button.styles'

const slideRightIn = keyframes({
  '0%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)', opacity: 1 },
})

const slideRightOut = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(100%)', opacity: 0 },
})

export const ToastRoot = styled('div', {
  animation: `${slideRightIn} forwards 0.7s`,
  width: 'fit-content',
  minWidth: '296px',
  border: '1px solid red',
  padding: '$2',
  borderRadius: '$3',
  boxShadow: '1px 4px 5px rgba(222, 215, 194, 0.25)',
  color: '$tertiary-900',

  variants: {
    tone: {
      success: {
        backgroundColor: '$success-100',
        border: '1px solid rgba($tag-green, 0.15)',
      },
      error: {
        backgroundColor: '$error-100',
        border: '1px solid rgba($tag-red, 0.15)',
      },
      info: {
        backgroundColor: '#F5F5F5',
        border: '1px solid $tertiary-200',
      },
    },
    isSlidingOut: {
      true: {
        animation: `${slideRightOut} forwards 0.7s`,
      },
    },
  },
  defaultVariants: {
    tone: 'info',
  },
})

export const RoundContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  size: '$5',
  minWidth: '$5',
  borderRadius: '$round',
  backgroundColor: '$accent-white',
})

export const CloseButton = styled('button', {
  ...buttonReset,
  cursor: 'pointer',
  color: '$surface-500',
})
