import React from 'react'
import { VariantProps } from '@stitches/react'
import { VerticalSeparatorRoot } from './VerticalSeparator.styles'

export type VerticalSeparatorOwnProps = VariantProps<typeof VerticalSeparatorRoot>

export const VerticalSeparator = (props: VerticalSeparatorOwnProps) => {
  return <VerticalSeparatorRoot {...props} />
}

VerticalSeparator.displayName = 'VerticalSeparator'
