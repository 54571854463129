import { useEffect, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { useShoppingCartSidebarProvider } from 'contexts/ShoppingCartSidebarContext'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import { Badge } from 'components/Badge'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { Icon } from 'components/Icon'
import { Hr } from 'components/Hr'
import { Link } from 'components/Link'
import { formatCurrency } from 'utils/currency'
import { OrderType } from 'models/Order'
import { useFetchShoppingCart } from 'features/ECommerce/api/queries/useFetchShoppingCart'
import { LoaderAnimation } from '../Loader/LoaderAnimation'
import { ShoppingCartItem } from './components/ShoppingCartItem'
import {
  Content,
  LogoContainer,
  Scrollable,
  ShoppingCartSidebarRoot,
  SidebarFooter,
  SidebarPadding,
} from './ShoppingCartSidebar.styles'

import badge from '../../../../public/assets/badge-3-red.svg'

export const ShoppingCartSidebar = () => {
  const { t } = useTranslation()
  const { data: shoppingCart, status } = useFetchShoppingCart(
    {
      discount: {
        orderType: OrderType.PRODUCTS_SUBSCRIPTION,
      },
    },
    { retry: false, retryOnMount: false }
  )
  const { setShowSidebar } = useShoppingCartSidebarProvider()

  useEffect(() => {
    setIsShown(true)
  }, [])

  const total = useMemo(() => {
    return (
      shoppingCart?.items.reduce((sum, item) => {
        return sum + item.quantity
      }, 0) || 0
    )
  }, [shoppingCart])

  const [isShown, setIsShown] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const hide = () => {
    setIsShown(false)
    setTimeout(() => setShowSidebar(false), 300)
  }

  const remove = () => {
    timeoutRef.current = setTimeout(hide, 300)
  }

  const cancel = () => {
    if (!timeoutRef.current) return

    clearTimeout(timeoutRef.current)
    timeoutRef.current = undefined
  }

  if (status === 'loading') return <LoaderAnimation description={t('common:loader.description')} />

  return (
    <ShoppingCartSidebarRoot onMouseLeave={remove} onMouseOver={cancel} isShown={isShown}>
      <SidebarPadding>
        <Stack gap="2" direction="column">
          <LogoContainer>
            <Image src={badge} alt="" />
          </LogoContainer>
          <Content>
            <Stack gap="3" direction="column">
              <Stack justifyContent="space-between" alignItems="center">
                <Text size="h5" weight="medium" color="primary-800">
                  {t('common:cart.title')}
                </Text>
                <Badge label="" count={total} color="primary-500" placement="rightTop">
                  <Link href="/cart">
                    <a aria-label={t('header:shoppingCart')} title={t('header:shoppingCart')}>
                      <Icon name="shopping-cart" css={{ display: 'flex' }} />
                    </a>
                  </Link>
                </Badge>
              </Stack>

              <Scrollable>
                <Stack gap="2" direction="column">
                  {shoppingCart?.items.map((item) => {
                    return (
                      <span key={item.productId}>
                        <Hr />
                        <ShoppingCartItem product={item} />
                      </span>
                    )
                  })}
                </Stack>
              </Scrollable>
            </Stack>
          </Content>
        </Stack>
      </SidebarPadding>
      <SidebarFooter>
        <Stack gap="3" direction="column">
          <Hr />
          <SidebarPadding>
            <Stack gap="2" direction="column">
              <Stack justifyContent="space-between" alignItems="center" css={{ px: '$1' }}>
                <Text>{t('common:cart.subtotal')}</Text>
                <Text weight="medium">
                  {formatCurrency(
                    shoppingCart?.items.reduce(
                      (acc, item) => acc + item.quantity * item.price.total,
                      0
                    ) || 0
                  )}
                </Text>
              </Stack>
              <Link href="/cart" passHref>
                <Button as="a">{t('common:cart.cta')}</Button>
              </Link>
            </Stack>
          </SidebarPadding>
        </Stack>
      </SidebarFooter>
    </ShoppingCartSidebarRoot>
  )
}
