import { LoaderWrapper } from './Loader.styles'

export const LoaderAnimation = ({ description }: { description: string }) => {
  return (
    <LoaderWrapper>
      <object type="image/svg+xml" data={`/assets/animation-loader.svg`} aria-label={description} />
    </LoaderWrapper>
  )
}

LoaderAnimation.displayName = 'LoaderAnimation'
