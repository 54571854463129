import * as RadixCollapsible from '@radix-ui/react-collapsible'
import { buttonReset } from 'components/Button/Button.styles'
import { keyframes, styled } from 'stitches.config'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
})

const slideUp = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
})

const CollapsibleHeader = styled(RadixCollapsible.Trigger, {
  ...buttonReset,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '$2 $4',
  borderRadius: '$1',
  border: '1px solid $colors$tertiary-300',
})

const CollapsibleContent = styled(RadixCollapsible.Content, {
  overflow: 'hidden',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms ease-out`,
    animationFillMode: 'forwards', 
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms ease-in`,
    animationFillMode: 'forwards', 
  },
})

export const Collapsible = {
  Root: RadixCollapsible.Root,
  Trigger: CollapsibleHeader,
  Content: CollapsibleContent,
}
