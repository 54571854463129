import * as Popover from '@radix-ui/react-popover'
import { styled, keyframes } from 'stitches.config'

const slideDown = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

export const PopoverContent = styled(Popover.Content, {
  maxWidth: '400px',
  borderRadius: '$1',
  backgroundColor: 'transparent',
  animationDuration: '250ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',

  '&[data-side="top"]': {
    animationName: slideUp,
  },

  '&[data-side="bottom"]': {
    animationName: slideDown,
  },
})

export const PopoverArrow = styled(Popover.Arrow, {
  fill: 'none',
})
