import { TranslationKeys } from 'locales/translationKeys'
import { TranslationQuery } from 'next-translate'
import useTranslationBase from 'next-translate/useTranslation'

export const useTranslation = (defaultNs?: string) => {
  const { t, ...rest } = useTranslationBase(defaultNs)

  return {
    t: <B extends boolean = false>(
      key: TranslationKeys,
      query?: TranslationQuery,
      options?: {
        returnObjects?: B
        fallback?: string | string[]
        default?: string
        ns?: string
      }
    ): B extends false ? string : string[] => t(key, query, options),
    ...rest,
  }
}
