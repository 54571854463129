import {
  useQuery,
  UseQueryOptions,
  QueryClient,
  QueriesObserver,
  useQueryClient,
} from 'react-query'
import { dehydrate } from 'react-query/hydration'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'
import { ShoppingCart } from 'models/Product'
import { getClientId } from 'utils/getClientId'
import { useEffect, useState } from 'react'
import { ApplyDiscountRequest } from 'models/Discount'

export namespace FetchShoppingCart {
  export type Response = ShoppingCart
  export type Variables = {
    clientId: string
    discount?: ApplyDiscountRequest
  }
  export type Error = Api.Error
  export type Options = UseQueryOptions<Response, Error>
}

const createKey = (data: FetchShoppingCart.Variables) => ['shoppingCart', { data }]

const queryFetcher = (data: FetchShoppingCart.Variables) => () => {
  if (!data.clientId) return Promise.reject({} as FetchShoppingCart.Response)
  return ApiClient.post<FetchShoppingCart.Response>(`/v1/shopping-cart/preview`, { data })
}

export const useFetchShoppingCart = (
  data: Omit<FetchShoppingCart.Variables, 'clientId'>,
  options?: FetchShoppingCart.Options
) => {
  const [clientId, setClientId] = useState('')
  const queryClient = useQueryClient()

  useEffect(() => {
    setClientId(getClientId() || '')
    const observer = new QueriesObserver(queryClient, [{ queryKey: ['shoppingCart'] }])

    observer.subscribe(() => setClientId(getClientId() || ''))
    const unsubscribe = observer.subscribe(() => unsubscribe())

    return unsubscribe
  }, [queryClient])

  return useQuery<FetchShoppingCart.Response, FetchShoppingCart.Error>(
    createKey({ ...data, clientId }),
    queryFetcher({ ...data, clientId }),
    {
      ...options,
      enabled: clientId.length > 0,
    }
  )
}

export const prefetchShoppingCart = async (
  clientId: string,
  options?: FetchShoppingCart.Options
) => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery<FetchShoppingCart.Response, FetchShoppingCart.Error>(
    createKey({ clientId }),
    queryFetcher({ clientId }),
    options
  )

  return {
    dehydratedState: dehydrate(queryClient),
  }
}
